

























import { Component, Prop, Vue } from 'vue-property-decorator'


@Component
export default class Alert extends Vue {
  @Prop({ default: 'primary' })
  variant !: string;

  get alertClasses () : string {
    return `alert-${this.variant} ${this.disableShake?'':'alert-shake'}`
  }

  get icon () : string {
    if (this.variant === 'danger') {
      return 'fa-light fa-ban'
    }

    return 'fa-light fa-info-circle'
  }

  @Prop({default: false, type: Boolean})
  disableShake!: boolean;

  @Prop({ default: '' })
  title!: string;

  display = true;
  restartAnimation(){ 
    this.display = false;
    this.$nextTick(() => this.display = true);
  }
}
